@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '../../style/abstract/mixins';

:root {
    --header-logo-width: 120px;
    --header-logo-height: 23px;
    --header-height: 65px;
    --header-nav-height: 65px;
    --header-menu-height: 112px;
    --header-submenu-height: 62px;
    --header-menu-nav-height: 112px;
    --menu-footer-height: 26px;
    --header-gap-between-icons: 40px;
    --menu-page-mobile-top-position: 75px;
    --header-margin-top: 60px;

    @include desktop {
        --header-logo-width: 178px;
        --header-logo-height: 33px;
        --header-nav-height: 86px;
        --header-height: calc(var(--header-nav-height) + var(--header-top-menu-height) + var(--menu-total-height));
    }
}

.Header {
    min-height: var(--header-height);
    margin-block-start: var(--demo-notice-height);

    @include desktop {
        position: static;
        margin-block-start: var(--demo-notice-height);
    }

    @include mobile {
        position: static;
        margin-block-start: var(--header-margin-top);

        &_name_filter,
        &_name_menu,
        &_name_MENU_SUBCATEGORY,
        &_name_search {
            position: fixed;
        }
    }

    >* {
        max-width: inherit;
    }

    &-Menu {
        min-height: 78px;
    }

    &-Wrapper {
        margin-block-end: 0;

        &_isCheckout {
            margin-block-end: var(--header-nav-height);
        }

        &_isCrawler {
            .Header-Nav {
                .Header-Menu,
                .Menu-MenuWrapper,
                .Menu-SubMenu {
                    @include mobile {
                        display: block;
                        position: absolute;
                    }
                }

                .Header-Menu {
                    @include mobile {
                        inset-inline-start: -999px;
                    }
                }
            }
        }
    }

    &-TopMenu {
        pointer-events: none;

        >* {
            pointer-events: auto;
        }
    }

    &-Button {
        /* stylelint-disable-next-line */

        &_type_wishlist,
        &_type_minicart {
            @include desktop {
                opacity: 1;
                pointer-events: all;
                overflow: visible;
                cursor: pointer;
                height: auto;
                width: auto;
                margin-inline-start: var(--header-gap-between-icons);
            }
        }

        &_type_share {
            @include mobile {
                margin-block-start: auto;
                margin-block-end: auto;
            }
        }
    }

    &-IconWrapper {
        cursor: pointer;
        margin-block-start: 7px;

        @include flex($dir: column, $align: center);
    }

    &-IconLabel {
        font-size: $fs-xxs;
        font-weight: $fw-light;
        color: $color-primary;
        margin-block-start: 5px;
        text-align: center;
    }

    &-CompareButtonWrapper {
        height: auto;
        margin-inline-start: var(--header-gap-between-icons);

        a {
            all: unset;
        }
    }

    &-WishlistButtonWrapper {
        all: unset;
    }

    &-CompareCount,
    &-MinicartItemCount {
        background: $error;
        font-family: inherit;
        font-size: $fs-xxxs;
        line-height: 1.6;
        font-weight: $fw-semi-bold;
        border: solid 2px $white;
        width: 19px;
        height: 19px;

        @include desktop {
            inset-block-start: 0;
            inset-inline-end: 0;
        }
    }

    &-CompareCount {
        @include desktop {
            inset-inline-end: 12px;
        }
    }

    &-MyAccountContainer {
        @include desktop {
            margin-inline-start: 0;
        }
    }

    &-MyAccount {
        @include desktop {
            height: auto;
        }
    }

    &-LogoWrapper {
        @include mobile {
            margin-block: auto;
            margin-inline: 0 auto;
        }
    }

    &_name_popup {
        @include mobile {
            .Header-Nav {
                --header-nav-background: #{$default-primary-base-color};
                --header-nav-color: #{$white};
                --header-nav-height: 48px;

                background-color: var(--header-nav-background);
                justify-content: flex-start;
                padding-inline: 16px;

                h1 {
                    font-size: 20px;
                    color: var(--header-nav-color);
                    text-align: start;
                    position: relative;
                    max-width: unset;
                    margin: 0;
                }

                svg {
                    fill: var(--header-nav-color);
                }
            }

            .Header-LogoWrapper {
                display: none;
            }

            .Header-Button_type_close {
                margin-block-start: -3px;
                order: 2;
            }
        }
    }

    &_name_menu,
    &_name_search {
        @include mobile {
            z-index: 305;

            .Header-Nav {
                flex-wrap: wrap;
                height: var(--header-menu-nav-height);

                h2 {
                    margin: 16px 0 0;
                }
            }

            .Header-LogoWrapper {
                opacity: 0;
            }
        }
    }

    &_name_search {
        .SearchField_isActive .SearchField-Input {
            background-color: var(--search-field-background);
            padding-inline-end: 50px;
        }
    }

    &_name_MENU_SUBCATEGORY {
        height: var(--header-submenu-height);
        border: 0;

        .Header-LogoWrapper {
            display: none;
        }

        .Menu {
            &-TitleWrapper {
                @include flex($align: center);

                padding-block-start: 10px;
            }

            &-Title {
                margin-block-end: 16px;
            }
        }
    }

    &_name_filter {
        h2 {
            text-align: start;
            padding-inline-start: 12px;
            margin-block-end: 0;
        }

        .Menu-Title {
            order: 1;
        }

        .Header-Nav {
            @include mobile {
                align-items: center;
            }
        }

        .ChevronIcon {
            height: 20px;
            width: 20px;
            fill: $color-primary;
        }
    }

    &-BarTitle {
        display: flex;
        align-items: center;
        justify-content: center;

        .Header-Title {
            overflow: visible;
            position: relative;
            inset-block-start: 0;
            height: auto;
            font-size: 15px;
            line-height: 21px;
        }
    }

    &-Nav {
        pointer-events: none;

        >* {
            pointer-events: auto;
        }

        .CategoryFilterOverlay-TotalProducts {
            width: 100%;
            margin-block-start: 0;
            display: none;
        }
    }

    &-Title {
        &_isVisible {
            @include mobile {
                opacity: 1;
                max-width: calc(100% - 20px);
                text-align: start;
                margin: 0;
                position: relative;
            }
        }
    }

    &-TopMenu,
    &-Nav {
        margin: auto;
        max-width: var(--content-wrapper-width);

        @include mobile {
            background: $white;

            &:not(.Header-Nav) {
                padding-block-start: 20px;
            }

            @include flex($wrap: wrap, $align: flex-start, $justify: flex-start);
        }
    }

    &_name_category {
        inset-inline-start: 0;

        .CategoryFilterOverlay-TotalProducts {
            display: block;

            @include desktop {
                display: none;
            }
        }
    }

    &-BarExitButton {
        position: absolute;
        inset-inline-end: 15px;
        inset-block-start: 50%;
        transform: translateY(-50%);
        cursor: pointer;
    }

    &-NavAuth {
        height: 70px;

        @include mobile {
            align-items: center;
        }
    }

    &-Button_type_back {
        display: none;
    }

    &-MyAccountWrapper {
        z-index: 100;
    }

    &_isCheckout {
        border-bottom: 0;

        .Header {
            &-LogoWrapper {
                margin: auto;

                @include mobile {
                    margin-inline-start: 0;
                    max-width: 100%;
                    opacity: 1;
                }
            }

            &-Nav {
                padding-block-start: 0;
            }
        }
    }

    &_isCategoryPage {
        @include desktop {
            z-index: 101;
        }
    }
}
